import classNames from "classnames"
import { Link as NextLink } from "next-view-transitions"
import * as React from "react"

import * as styles from "./Link.css"

type NextLinkProps = Parameters<typeof NextLink>[0]

export type LinkProps = styles.LinkVariant & {
	children?: React.ReactNode
	className?: string
} & NextLinkProps

export default function Link({
	underline = "always",
	highlight = "hover",
	color = "inherit",
	children,
	className,
	...restProps
}: LinkProps) {
	return (
		<NextLink
			{...restProps}
			className={classNames(
				styles.link({ underline, highlight, color }),
				className,
			)}
		>
			{children}
		</NextLink>
	)
}
